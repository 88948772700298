import * as permissionText from './permissionText';

const fr = {
  abbreviations: {
    code: {
      CCB: 'ACE',
      CFSC: 'CFSC',
      CRA: 'ARC',
      ISP: 'FSI',
      OPEC: 'OPEC',
      PIPEDA: 'LPRPDE',
      TTY: 'ATS',
      ISED: 'ISDE',
      YWCA: 'YWCA',
    },
    name: {
      CCB: 'Allocation canadienne pour enfants',
      CFSC: 'Computers For Success Canada',
      CRA: 'Agence du revenu du Canada',
      ISP: 'Fournisseur de services Internet',
      OPEC: 'Ordinateurs pour l’excellence Canada',
      PIPEDA:
        'Loi sur la protection des renseignements personnels et les documents électroniques',
      TTY: 'Téléimprimeur (ATS)',
      ISED: 'Innovation, sciences et développement économique Canada',
    },
  },
  addressEntry: {
    address: 'Adresse',
    addressLineTwo: 'Appartement, suite, unité, immeuble, étage, etc.',
    aria: {
      postalCodeInput: 'Entrez le code postal',
    },
    city: 'Ville',
    computerEmailPhoneHelp:
      'Afin de finaliser votre commande, nous avons besoin d’un numéro de portable valide qui nous permettra de vous envoyer un message texte de confirmation, incluant votre numéro de commande. Vous pouvez aussi fournir une adresse courriel si vous désirez également recevoir votre confirmation par courriel.',
    englishKeyboard: 'Clavier anglais',
    firstName: 'Prénom',
    frenchKeyboard: 'Clavier français',
    ifYouCanNot: 'Si vous ne trouvez pas l’adresse à partir de cette recherche',
    ifYouHaveEmail:
      'Si vous avez une adresse courriel ou un numéro de téléphone portable, nous vous invitons à l’inscrire ici. Vous recevrez l’information nécessaire pour recevoir de l’Internet à faible coût.',
    lastName: 'Nom de famille',
    locale: 'Quel clavier vous convient?',
    needHelp: 'Besoin d’aide?',
    poBoxNote:
      'Les ordinateurs ne peuvent pas être livrés à une adresse de case postale.',
    postalCode: 'Code postal',
    province: 'Province',
    startTyping: 'Commencez à taper et sélectionnez l’adresse correspondante.',
    useThisForm: 'utilisez ce formulaire',
  },
  application: {
    title: 'Familles branchées',
  },
  aria: {
    messages: {
      addressConfirmation: 'A visité la page de confirmation de l’adressee.',
      addressConfirmationSubmit:
        'Page de confirmation de l’adressee a été soumise.',
      applicationError: 'A visité la page d’erreur d’application',
      computer: 'Est allé à la page d’offre d’ordinateur.',
      computerAddressConfirmation:
        'A visité la page de confirmation d’adresse pour l’ordinateur.',
      computerAddressConfirmationSubmit:
        'Page de confirmation d’adresse pour l’ordinateur a été soumise.',
      computerAddressEntry: 'A visité la page d’adresse d’envoi postal.',
      computerAddressEntrySubmit:
        'Page d’adresse d’envoi postal a été soumise.',
      contactInfo: 'A visité la page de contact',
      contactInfoSubmit: 'Page de contact a été soumise.',
      finish: 'A visité la page de contact FSI',
      finishComputerAddressEntrySubmit:
        'Formulaire d’adresse d’envoi postal a été soumise.',
      finishContactInfoSubmit: 'Formulaire de contact a été soumise.',
      internetService: 'A visité la page du service Internet',
      languageSelection: 'A visité la page Langues.',
      mailingAddressEntry: 'A visité la page d’adresse postale.',
      mailingAddressEntrySubmit: 'Page d’adresse postale a été soumise.',
      noInventory: 'A visité la page de pas d’inventaire ordinateur.',
      noIsps: 'A visité la page de pas de FSI.',
      noLetter: 'A visité la page Lettre non reçue.',
      noPermission: 'A visité la page d’autorisation refusée.',
      permission: 'A visité la page d’autorisation.',
      pinEntry: 'A visité la page d’entrée de code.',
      pinEntrySubmit: 'Page d’entrée de code a été soumise.',
      provider: 'A visité la page question-FSI.',
      providerSubmit: 'Page de  question-FSI a été soumise.',
      serviceAddressEntry: 'A visité la page de livraison Internet.',
      serviceAddressEntrySubmit: 'Page de livraison Internet a été soumise.',
      termsOfService: 'A visité la page des conditions d’utilisation.',
      welcome: 'A visité la page d’accueil.',
    },
  },
  buttons: {
    close: 'Fermer',
    continue: 'Continuer',
    saveAndCompleteOrder: 'Enregistrer et compléter la commande',
  },
  errors: {
    computerEmailInvalid:
      'Nous n’avons pas été en mesure d’utiliser le courriel <2>{{email}}</2> pour soumettre votre commande. Veuillez <5>vérifier votre adresse courriel</5> et essayer à nouveau.',
    computerPhoneInvalid:
      'Nous n’avons pas été en mesure d’utiliser le numéro de téléphone <2>{{phone}}</2> pour soumettre votre commande. Veuillez <4>vérifier votre numéro de téléphone</4> et essayer à nouveau.',
    notifyUserEmailFail:
      'Nous n’avons pas pu vous envoyer cette information par courriel. Assurez-vous de noter ou d’imprimer les coordonnées du FSI.',
    notifyUserFail:
      'Nous n’avons pas pu vous envoyer cette information par courriel ou par SMS. Assurez-vous de noter ou d’imprimer les coordonnées du FSI.',
    notifyUserPhoneFail:
      'Nous n’avons pas pu vous envoyer cette information par SMS. Assurez-vous de noter ou d’imprimer les coordonnées du FSI.',
    serverErrorText:
      "Veuillez nous excuser, mais notre site rencontre actuellement des difficultés pour rechercher les codes. Cela peut être dû à une connexion internet faible. Veuillez réessayer depuis une bibliothèque locale ou un autre endroit disposant d'une connexion internet plus stable.",
    title: 'Pardon',
    unexpectedError: 'Une erreur imprévue s’est produite.',
  },
  formValidation: {
    cityRequired: 'La ville est obligatoire.',
    confirmEmailRequired: 'Courriel de confirmation est obligatoire.',
    emailFormat: 'Veuillez fournir une adresse courriel valide.',
    emailMatch:
      'Désolé, le courriel fourni ne correspond pas au courriel de confirmation.',
    emailMatchSkip:
      'Désolé, le courriel fourni ne correspond pas au courriel de confirmation. Veuillez vous assurer qu’ils soient identiques ou <2>continuez sans fournir votre contact</2>.',
    firstNameRequired: 'Le prénom est obligatoire.',
    lastNameRequired: 'Le nom de famille est obligatoire.',
    line1Required: 'L’adresse est obligatoire.',
    mustEnterCode: 'Vous devez entrer un code.',
    phoneFormat: 'Veuillez fournir un numéro de téléphone mobile valide.',
    phoneCountry:
      'Veuillez fournir un numéro de téléphone mobile canadien valide.',
    pinFormat:
      'Le code doit contenir 8 caractères (lettres et nombres) et doit commencer par la lettre “A“ ou “B“.',
    pinInvalid:
      'Désolé, le code que vous avez inscrit n’est pas admissible en ce moment. Pour plus d’informations, contactez Innovation, Sciences et Développement économique Canada par courriel à ised-isde@canada.ca, ou téléphonez sans frais le 1 800 328 6189.',
    postOfficeBoxServiceExcluded:
      'Désolé, les services Internet ne peuvent pas être fournis à une adresse de case postale. Veuillez fournir une autre adresse.',
    postOfficeBoxShippingExcluded:
      'Désolé. Nous ne pouvons pas livrer à des cases postales. Veuillez fournir une autre adresse.',
    postalCodeInvalid: 'Ce code postal n’est pas valide.',
    postalCodeMustMatchProvince:
      'Le code postal ne correspond pas à la province sélectionnée.',
    postalCodeRequired: 'Le code postal est obligatoire.',
    provinceRequired: 'La province est obligatoire.',
    required: 'Ce champ est obligatoire.',
  },
  general: {
    about: 'À propos',
    aboutConnectingFamilies: 'À propos de Familles branchées',
    back: 'Revenir en arrière',
    chatNow: 'Discutez en direct',
    computersForSuccessCanada: 'Ordinateurs pour l’excellence Canada',
    connectingFamilies: 'Familles branchées',
    disclaimer:
      'Ceci est le site de test. Veuillez utiliser le site de <1>production</1> pour les clients.',
    disclosurePolicy: 'Politique de divulgation',
    letterImage: 'Une lettre du gouvernement du Canada',
    notWorkingCode:
      'Votre code ne fonctionne pas ? Pour obtenir de l’aide, veuillez contacter le',
    generalQuestions: 'Pour des questions d’ordre général, veuillez appeler l’',
    optional: 'Facultatif',
    or: 'ou',
    at: 'au',
    termsOfService: 'Conditions d’utilisation',
    visitLibrary:
      'Si vous avez besoin d’une assistance supplémentaire, vous avez également la possibilité de vous rendre dans votre bibliothèque publique locale.',
    voice: 'voix',
  },
  links: {
    connectingFamilies: 'https://connecting-families.ca',
    connectingFamiliesFaq:
      'https://www.ic.gc.ca/eic/site/111.nsf/fr/h_00002.html',
  },
  paths: {
    addressConfirmation: 'confirmation-d-adresse',
    applicationError: 'erreur-d-application',
    computer: 'ordinateur',
    computerAddressConfirmation: 'confirmation-d-adresse-ordinateur',
    computerAddressEntry: 'saisie-d-adresse-ordinateur',
    contactInfo: 'info-contact',
    finish: 'termine',
    internetService: 'service-internet',
    mailingAddressEntry: 'saisie-d-adresse-postale',
    noInventory: 'pas-de-inventaire',
    noIsps: 'pas-de-fsi',
    noLetter: 'pas-de-lettre',
    noPermission: 'pas-de-permission',
    permission: 'permission',
    pinEntry: 'entrer-code',
    provider: 'fournisseur',
    serviceAddressEntry: 'saisie-d-adresse-service',
    welcome: 'bienvenue',
  },
  providerChoices: {
    access: 'Access Communications',
    beanfield: 'Beanfield',
    bell: 'Bell',
    ccap: 'CCAP',
    cogeco: 'Cogeco',
    hay: 'Hay Communications',
    noneOfTheAbove: 'Aucune de ces réponses.',
    northwestel: 'Northwestel, Inc.',
    rogers: 'Rogers',
    sasktel: 'Sasktel',
    shaw: 'Shaw',
    tbaytel: 'Tbaytel',
    telus: 'Telus',
    unsure: 'Je ne suis pas sûr.',
    videotron: 'Vidéotron',
    westman: 'Westman Media Cooperative',
    quadro: 'Quadro Communication Co-Operative Inc.',
    ruralnet: 'Rural Net',
  },
  provinces: {
    AB: 'Alberta',
    BC: 'Colombie-Britannique',
    MB: 'Manitoba',
    NB: 'Nouveau-Brunswick',
    NL: 'Terre-Neuve-et-Labrador',
    NS: 'Nouvelle-Écosse',
    NT: 'Territoires-du-nord-ouest',
    NU: 'Nunavut',
    ON: 'Ontario',
    PE: 'Ïle-du-Prince-Édouard',
    QC: 'Québec',
    SK: 'Saskatchewan',
    YT: 'Yukon',
  },
  screens: {
    addressConfirmation: {
      affirmative: 'Oui, utilisez cette adresse',
      negative: 'Non, utilisez une autre adresse à la place',
      prompt:
        'Voulez-vous obtenir de l’Internet à faible coût à l’adresse indiquée sur votre lettre ?',
      title: 'Confirmation de l’adressee',
    },
    applicationError: {
      heading:
        'Un problème s’est produit durant la sauvegarde de vos renseignements.',
      paragraph1:
        'Nous sommes désolés, mais notre site a des difficultés à sauvegarder vos renseignements. Le problème a été signalé et nous travaillons pour le résoudre. Veuillez réessayer dans 10 à 20 minutes. Si ce message réapparaît, veuillez patienter et réessayer, car le problème pourrait prendre plus de temps à résoudre.',
      title: 'Erreur d’application',
      tryAgain: 'Veuillez réessayer',
    },
    computer: {
      noOption: 'Non, je ne veux pas',
      paragraph1:
        'Par l’initiative <1>Familles branchées</1>, vous êtes aussi admissibles à recevoir gratuitement, de la part du <4>gouvernement du Canada</4>, un ordinateur de bureau usagé.',
      paragraph2:
        'L’ordinateur sera à vous et vous sera livré <2>sans frais</2>.',
      prompt: 'Avez-vous besoin d’un ordinateur?',
      title: 'Offre d’ordinateur',
      yesOption: 'Oui, j’ai besoin d’un ordinateur',
    },
    computerAddressConfirmation: {
      affirmative: 'Oui, passer la commande et continuer',
      negative: 'Non, laissez-moi corriger mon adresse',
      paragraph1:
        'Veuillez vous assurer que l’adresse que vous avez entrée est bonne et contient toutes les informations nécessaires (numéro d’unité ou d’appartement, ville, code postal, etc.) pour livrer l’ordinateur.',
      prompt: 'Aidez-nous à confirmer votre adresse',
      title: 'Confirmation d’adresse pour l’ordinateur',
    },
    computerAddressEntry: {
      paragraph1:
        'Pour vous assurer de recevoir un ordinateur, veuillez entrer votre adresse postale, votre prénom et votre nom de famille.',
      prompt: 'Où devons-nous livrer l’ordinateur?',
      title: 'Adresse d’envoi postal',
    },
    contactInfo: {
      confirmEmailLabel:
        'Afin de confirmer votre adresse courriel veuillez l’inscrire à nouveau',
      emailLabel: 'Adresse de courriel',
      emailPaste:
        'Veuillez vous assurer que les adresses courriel sont les mêmes.',
      phoneLabel: 'Numéro de téléphone portable',
      prompt:
        'Merci. Vos renseignements nous permettront de vous fournir l’information nécessaire.',
      skipThisStep: 'sauter cette étape',
      spamWarning:
        'Si vous fournissez une adresse courriel, assurez-vous de vérifier votre dossier de pourriels ou de courrier indésirable afin de ne pas manquer de messages importants concernant l’initiative. Si vous ne fournissez pas de coordonnées de contact, assurez-vous d’avoir du papier et un crayon pour écrire vos informations.',
      span1: 'Si vous préférez ne pas fournir de coordonnées',
      title: 'Contact',
    },
    finish: {
      beSureToWriteDown:
        'Assurez-vous d’avoir noté le numéro de téléphone de tous les fournisseurs de services avant de quitter ce site.',
      closer: {
        paragraph1: '<0>Merci !</0>',
        paragraph2:
          'Veuillez laisser cette page ouverte jusqu’à ce que vous ayez contacté un fournisseur de services Internet ou que vous ayez sauvegardé les renseignements sur cette page pour utilisation future.',
      },
      changeAddress: 'changer l’adresse?',
      chosenIspSpan1: 'et',
      chosenIspSpan2: 'donnez-leur le code',
      chosenIspSpan3: 'de votre lettre.',
      computerImage: 'une image d’un ordinateur',
      failSaveApplication:
        'Une erreur s’est produite lors de la sauvegarde de votre application',
      ifYouHaveEmail:
        'Si vous avez une adresse courriel ou un numéro de téléphone portable, nous pouvons vous envoyer une confirmation avec toute l’information nécessaire afin de recevoir de l’Internet à faible coût.',
      infoHasBeenSent:
        'Cette information a aussi été envoyée à votre adresse courriel et votre téléphone portable.',
      infoHasBeenSentEmail:
        'Cette information a également été envoyée à votre adresse courriel.',
      infoHasBeenSentPhone:
        'Cette information a également été envoyée à votre appareil mobile.',
      listItem1: 'Votre code, tel qu’indiqué sur votre lettre:',
      listItem2: 'L’adresse à laquelle vous voulez Internet:',
      multipleHighlightedIsps:
        'Pour obtenir votre Internet, communiquez maintenant avec l’un de ces fournisseurs de services Internet participants.',
      noChosenIspSpan1: 'Donnez-leur le code',
      noChosenIspSpan2: 'de votre lettre.',
      numberFirstCard: {
        call: 'Appelez',
        visitWebsite: 'visitez le site Web de {{name}}',
      },
      optedInToFreeComputerParagraph1:
        'Merci d’avoir choisi de recevoir du <1>gouvernement du Canada</1> un ordinateur de bureau remis à neuf et gratuit.',
      optedInToFreeComputerParagraph2:
        'Il vous sera livré aussi vite que possible. Pour plus d’informations, appelez : <2>1 800 328 6189</2> (vocal) ou <4>1 866 694 8389</4> (ATS).',
      or: 'ou',
      orderOnline: 'Commandez en ligne',
      paragraph1: 'Vous devrez fournir les informations suivantes:',
      paragraph2: 'Internet disponible',
      paragraph3:
        'Votre contrat avec un fournisseur de services internet contient des éléments importants, incluant des informations essentielles sur les frais de dépassement de données. À ce sujet et pour toute question, visitez <2>le site Familles branchées du gouvernement du Canada</2>.',
      pleaseWriteDown:
        'Veuillez noter par écrit les numéros de téléphone sur cette page.',
      send: 'Envoyer',
      sendMeThisInfo: 'Envoyez-moi cette information',
      siteFirstCard: {
        call: 'appelez',
        visitWebsite: 'Visitez le site Web de {{name}}',
      },
      singleHighlightedIsp:
        'Nous y sommes presque. Pour recevoir de l’Internet à faible coût, veuillez contacter {{name}} dès maintenant.',
      singleHighlightedIspNoProvider:
        'C’est presque fini. En ce moment, il n’y a qu’un FSI participant dans votre région. Pour obtenir votre Internet, contactez {{name}} maintenant.',
      tenDollarInternet:
        'Prix maximum de <1>10 $ par mois</1> plus taxes pour <4>10 Mbits/s</4>.',
      thankYou:
        'Merci. Assurez-vous de communiquer bientôt avec un fournisseur de services Internet.',
      tips:
        '<0>ASTUCE :</0> Vous pouvez prendre une photo de la lettre et vous l’envoyer par courriel. Vous pouvez la ranger en lieu sûr avec d’autres objets de valeur.',
      title: 'Contact FSI',
      twentyDollarInternet:
        'Prix maximum de <1>20 $ par mois</1> plus taxes pour une vitesse de téléchargement minimale de <3>50 Mbits/s</3>.',
      voice: 'Vocal',
      yesNeedComputer: 'Oui, j’ai besoin d’un ordinateur',
      youMayAlsoContact:
        'Vous pouvez aussi contacter un des fournisseurs participants de service Internet ci-dessous avec votre code.',
      needComputer:
        'Souhaitez-vous obtenir un équipement informatique gratuit ou à bas prix?',
      computerAvailable:
        'Vous êtes admissible à recevoir dans le cadre de l’initiative du gouvernement du Canada ‘les Familles Branchées’ un équipement informatique remis à neuf gratuitement ou à bas prix.',
      visitComputerProvider: 'Veuillez visiter le site web suivant',
    },
    internetService: {
      affirmative: 'Oui',
      negative: 'Non',
      paragraph1:
        'L’Internet à faible coût est disponible aux <1>nouveaux abonnés comme aux personnes qui reçoivent déjà Internet</1>. Vous devrez communiquer cette adresse lorsque vous appellerez le fournisseur de services Internet.',
      prompt: 'Avez-vous déjà Internet installé à cette adresse?',
      title: 'Service Internet',
    },
    mailingAddressEntry: {
      invalid:
        'Cette adresse ne correspond pas à l’adresse figurant sur la lettre du gouvernement du Canada.',
      prompt:
        'Merci. Veuillez inscrire l’adresse qui est indiquée sur la lettre.',
      title: 'Adresse postale',
    },
    noInventory: {
      heading: 'Nous sommes désolés.',
      paragraph:
        'En raison de la forte demande, il n’y a plus d’ordinateurs de disponibles dans votre province.',
      title: 'Pas d’inventaire',
    },
    noIsps: {
      heading:
        'Nous nous excusons mais il n’y a malheureusement pas de fournisseurs de services Internet participant dans votre région.',
      paragraph1:
        'Pour en savoir plus sur l’admissibilité à l’initiative Familles branchées et l’offre d’ordinateurs gratuits, veuillez appeler le <2><0>1 800 328 6189</0></2> (Vocal), <5>1 866 694 8389</5> (ATS) ou visiter le <8>site Familles branchées</8> du gouvernement du Canada.',
      paragraph2:
        'Veuillez conserver votre lettre puisque d’autres FSI dans votre région pourraient joindre l’initiative à une date ultérieure.',
      title: 'Pas de FSI',
    },
    noLetter: {
      heading: 'Nous sommes désolés.',
      paragraph1:
        'Pour participer à l’initiative Familles branchées, vous devez avoir reçu une lettre du gouvernement du Canada.',
      paragraph2:
        'Pour plus d’information, visitez <2>le site Familles branchées du gouvernement du Canada</2>.',
      title: 'Lettre non reçue',
    },
    noPermission: {
      heading: 'Si vous changez d’avis, vous pouvez toujours revenir.',
      paragraph1:
        'Pour recommencer, retournez simplement à <2>familles-branchées.ca</2>. <5>Vous aurez besoin de la lettre</5> que vous avez reçue par la poste, alors assurez-vous de la conserver en lieu sûr. Si possible, nous vous recommandons de prendre une photo de votre lettre.',
      paragraph2:
        'Conservez cette lettre en tout temps — même après avoir conclu votre contrat avec le FSI. Si vous perdez la lettre incluant votre code d’accès, elle ne pourra être remplacée',
      title: 'Autorisation refusée',
    },
    permission: {
      affirmative: 'J’accepte',
      negative: 'Je refuse',
      privacyLink: 'https://cfsc-opec.org/fr/politique-de-confidentialite/',
      prompt: 'Nous demandons votre permission.',
      text: permissionText.fr,
      title: 'Autorisation',
    },
    pinEntry: {
      aria: {
        codeInput: 'Entrez votre code',
      },
      helpText:
        'Le code contient huit caractères (lettres et numéros) et commence avec la lettre A ou la lettre B.',
      prompt: {
        header:
          'Veuillez entrer le code qui figure en haut et à droite de votre lettre.',
        paragraph1:
          'Si vous avez reçu une lettre avec un code de neuf caractères, <2>veuillez entrer uniquement les huit premiers caractères</2>.',
        paragraph2:
          'Si vous avez reçu une lettre avec un code de huit caractères, veuillez entrer tous les caractères.',
      },
      title: 'Entrée de code',
    },
    provider: {
      prompts: {
        internet: 'Quel est votre fournisseur actuel de services Internet?',
        telephone:
          'Quel est votre fournisseur actuel de services téléphoniques (résidentiels)?',
        television:
          'Quel est votre fournisseur actuel de services de télévision?',
      },
      title: 'Question-FSI',
    },
    serviceAddressEntry: {
      prompt:
        'Veuillez indiquer l’adresse où vous voulez recevoir de l’Internet à faible coût.',
      title: 'Livraison Internet',
      useThisAddress: 'Utilisez cette adresse',
    },
    termsAndConditions: {
      title: 'Modalités et conditions d’utilisation',
    },
    welcome: {
      affirmative: 'Oui, j’ai une lettre avec un code',
      heading:
        'Connecter les familles canadiennes avec le service Internet à domicile.',
      negative: 'Non, je n’ai pas cette lettre',
      paragraph1:
        'L’initiative Familles branchées du gouvernement du Canada, en partenariat avec des fournisseurs de service Internet (FSI), aide les personnes admissibles à recevoir des services d’Internet résidentiel abordables.',
      paragraph2:
        'Les prochaines questions vous aideront à communiquer avec un fournisseur de service Internet participant.',
      prompt:
        'Avez-vous reçu du gouvernement du Canada une lettre postale sur cette initiative?',
      title: 'Bienvenue',
    },
  },
};

export default fr;
