import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter, replace } from 'connected-react-router';
import { createBrowserHistory, History } from 'history';
import { Helmet } from 'react-helmet';
import { IAppState } from '../reducers';
import { I18nextProvider } from 'react-i18next';
import { isIE } from 'react-device-detect';

import { TitleBar } from './TitleBar';
import { Footer } from './Footer';
import { ErrorBoundary } from './ErrorBoundary';
import { getAllTranslations } from '../util';
import { i18n } from '../translations/i18n';
import { AccessibilityMessage } from './AccessibilityMessage';
import { ScrollToTop } from './ScrollToTop';

import WithGAPageView from './WithGAPageView';
import englishTranslations from '../translations/en-CA';

import './App.css';

import {
  LanguageSelection,
  Welcome,
  PinEntry,
  Permission,
  MailingAddressEntry,
  ServiceAddressEntry,
  ComputerAddressConfirmation,
  ComputerInfoEntry,
  AddressConfirmation,
  InternetService,
  Provider,
  Computer,
  ContactInfo,
  Finish,
  NoInventory,
  NoIsps,
  NoLetter,
  NoPermission,
  ApplicationError,
  IspShowcase,
} from './screens';
import { IENotAvailable } from './IENotAvailable';
import { setLocale } from '../actions/app';
import { bindActionCreators } from 'redux';
import { getKeyFromTranslatedPath } from '../util/getKeyFromTranslatedPath';
import { Language } from '../typings/Language';
import { getLocaleFromUrl } from '../util/getLocaleFromUrl';

interface IAppProps {
  locale: Language;
  history: History;
  year: number;
  setLocale: typeof setLocale;
  replace: typeof replace;
}

const history = createBrowserHistory();

class Component extends React.Component<IAppProps, {}> {
  public componentDidMount() {
    const lang = getLocaleFromUrl(window.location.pathname);
    this.props.setLocale(lang);
    i18n.changeLanguage(lang);

    // This runs when the back button is clicked
    history.listen((location) => {
      if (location.pathname === '/') {
        return;
      }
      const pageLocale = getLocaleFromUrl(location.pathname);

      const pageKey = getKeyFromTranslatedPath(
        pageLocale,
        location.pathname.split('/')[2]
      );

      const translations = getAllTranslations(`paths.${pageKey}`);
      this.props.replace(
        `/${this.props.locale}/${
          translations[this.props.locale === 'en' ? 0 : 1]
        }`
      );
    });
  }
  public componentDidUpdate(prevProps: IAppProps) {
    if (prevProps.locale !== this.props.locale) {
      i18n.changeLanguage(this.props.locale);
    }
  }

  public render() {
    const { locale, year } = this.props;
    const paths = englishTranslations.paths;
    return (
      <div id="root" className="app">
        <Helmet htmlAttributes={{ lang: locale }} />
        <I18nextProvider
          defaultNS="translations"
          initialLanguage={locale}
          i18n={i18n}
        >
          <ErrorBoundary>
            <AccessibilityMessage />
            <ConnectedRouter history={this.props.history}>
              <ScrollToTop>
                <Route path="/(.+)" component={TitleBar} />
                <Route
                  exact={true}
                  path="/"
                  render={() => <TitleBar hideLinks={true} />}
                />
                <main>
                  {isIE ? (
                    <IENotAvailable />
                  ) : (
                    <Switch>
                      <Route
                        exact={true}
                        path="/"
                        component={WithGAPageView(LanguageSelection, '/')}
                      />
                      <Route
                        exact={true}
                        path={`/:lang/(${getAllTranslations(
                          'paths.welcome'
                        ).join('|')})`}
                        component={WithGAPageView(Welcome, paths.welcome)}
                      />
                      <Route
                        exact={true}
                        path={`/:lang/(${getAllTranslations(
                          'paths.pinEntry'
                        ).join('|')})`}
                        component={WithGAPageView(PinEntry, paths.pinEntry)}
                      />
                      <Route
                        exact={true}
                        path={`/:lang/(${getAllTranslations(
                          'paths.permission'
                        ).join('|')})`}
                        component={WithGAPageView(Permission, paths.permission)}
                      />
                      <Route
                        exact={true}
                        path={`/:lang/(${getAllTranslations(
                          'paths.mailingAddressEntry'
                        ).join('|')})`}
                        component={WithGAPageView(
                          MailingAddressEntry,
                          paths.mailingAddressEntry
                        )}
                      />
                      <Route
                        exact={true}
                        path={`/:lang/(${getAllTranslations(
                          'paths.addressConfirmation'
                        ).join('|')})`}
                        component={WithGAPageView(
                          AddressConfirmation,
                          paths.addressConfirmation
                        )}
                      />
                      <Route
                        exact={true}
                        path={`/:lang/(${getAllTranslations(
                          'paths.serviceAddressEntry'
                        ).join('|')})`}
                        component={WithGAPageView(
                          ServiceAddressEntry,
                          paths.serviceAddressEntry
                        )}
                      />
                      <Route
                        exact={true}
                        path={`/:lang/(${getAllTranslations(
                          'paths.internetService'
                        ).join('|')})`}
                        component={WithGAPageView(
                          InternetService,
                          paths.internetService
                        )}
                      />
                      <Route
                        exact={true}
                        path={`/:lang/(${getAllTranslations(
                          'paths.provider'
                        ).join('|')})`}
                        component={WithGAPageView(Provider, paths.provider)}
                      />
                      <Route
                        exact={true}
                        path={`/:lang/(${getAllTranslations(
                          'paths.computer'
                        ).join('|')})`}
                        component={WithGAPageView(Computer, paths.computer)}
                      />
                      <Route
                        exact={true}
                        path={`/:lang/(${getAllTranslations(
                          'paths.computerAddressEntry'
                        ).join('|')})`}
                        component={WithGAPageView(
                          ComputerInfoEntry,
                          paths.computerAddressEntry
                        )}
                      />
                      <Route
                        exact={true}
                        path={`/:lang/(${getAllTranslations(
                          'paths.computerAddressConfirmation'
                        ).join('|')})`}
                        component={WithGAPageView(
                          ComputerAddressConfirmation,
                          paths.computerAddressConfirmation
                        )}
                      />
                      <Route
                        exact={true}
                        path={`/:lang/(${getAllTranslations(
                          'paths.contactInfo'
                        ).join('|')})`}
                        component={WithGAPageView(
                          ContactInfo,
                          paths.contactInfo
                        )}
                      />
                      <Route
                        exact={true}
                        path={`/:lang/(${getAllTranslations(
                          'paths.finish'
                        ).join('|')})/(${getAllTranslations(
                          'paths.computerAddressEntry'
                        )
                          .concat(
                            getAllTranslations(
                              'paths.computerAddressConfirmation'
                            )
                          )
                          .concat(getAllTranslations('paths.noInventory'))
                          .join('|')})?`}
                        component={WithGAPageView(Finish, paths.finish)}
                      />
                      <Route
                        exact={true}
                        path={`/:lang/(${getAllTranslations(
                          'paths.noInventory'
                        ).join('|')})`}
                        component={WithGAPageView(
                          NoInventory,
                          paths.noInventory
                        )}
                      />
                      <Route
                        exact={true}
                        path={`/:lang/(${getAllTranslations(
                          'paths.noLetter'
                        ).join('|')})`}
                        component={WithGAPageView(NoLetter, paths.noLetter)}
                      />
                      <Route
                        exact={true}
                        path={`/(${getAllTranslations('paths.noIsps').join(
                          '|'
                        )})`}
                        component={WithGAPageView(NoIsps, paths.noIsps)}
                      />
                      <Route
                        exact={true}
                        path={`/:lang/(${getAllTranslations(
                          'paths.noPermission'
                        ).join('|')})`}
                        component={WithGAPageView(
                          NoPermission,
                          paths.noPermission
                        )}
                      />
                      <Route
                        exact={true}
                        path={`/:lang/(${getAllTranslations(
                          'paths.applicationError'
                        ).join('|')})`}
                        component={WithGAPageView(
                          ApplicationError,
                          paths.applicationError
                        )}
                      />
                      {['development', 'stage'].includes(
                        process.env.REACT_APP_ENVIRONMENT_NAME || ''
                      ) && (
                        <Route
                          exact={true}
                          path="/isp-showcase"
                          component={IspShowcase}
                        />
                      )}
                    </Switch>
                  )}
                </main>
                <Route
                  path="/"
                  exact={true}
                  render={() => (
                    <Footer locale={locale} bilingual={true} year={year} />
                  )}
                />
                <Route
                  path="/(.+)"
                  render={() => <Footer locale={locale} year={year} />}
                />
              </ScrollToTop>
            </ConnectedRouter>
          </ErrorBoundary>
        </I18nextProvider>
      </div>
    );
  }
}

const mapStateToProps = (state: IAppState) => ({
  locale: state.locale,
  year: state.currentYear,
});

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      setLocale,
      replace,
    },
    dispatch
  );
};

export const App = connect(mapStateToProps, mapDispatchToProps)(Component);
